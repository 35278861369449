/* cyrillic-ext */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ca73cec4783f8990.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/913784d45cedca4d.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0a4a50fce8b9a620.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f177899fe43a13a8.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ca73cec4783f8990.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/913784d45cedca4d.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0a4a50fce8b9a620.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f177899fe43a13a8.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}.__className_6c0444 {font-family: '__Golos_Text_6c0444';font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__Zen_Dots_80af2f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/043de5c764a0a74e-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Zen_Dots_80af2f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3b1209046a6988e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Zen_Dots_Fallback_80af2f';src: local("Arial");ascent-override: 66.32%;descent-override: 19.25%;line-gap-override: 0.00%;size-adjust: 140.23%
}.__className_80af2f {font-family: '__Zen_Dots_80af2f', '__Zen_Dots_Fallback_80af2f';font-weight: 400;font-style: normal
}

